<template>
  <table class="login-table" v-if="isInstant">
    <tr>
      <td>
        <div class="title-table">{{ $t("LogOutTittle") }}</div>
      </td>
    </tr>
    <tr>
      <td>
        <div id="sumbit" class="form-group" v-if="!isSpinner">
          <button
            id="loginEnter"
            type="submit"
            class="btn btn-lg btn-primary btn-block"
            v-on:click="logout"
          >
            {{ $t("LogOut") }} {{ email }}
          </button>
        </div>
        <div class="forgot-table">
          <br />
        </div>
        <BottomCard />
      </td>
    </tr>
  </table>
</template>

<script>
import BottomCard from "@/components/BottomCard.vue";
import { CognitoUser } from "amazon-cognito-identity-js";
export default {
  name: "StatusCard",
  components: {
    BottomCard,
  },
  data() {
    return {
      email: this.$userInfo.getEmail(this.$clientId),
      isSpinner: false,
      isInstant: false,
    };
  },
  mounted() {
    if (this.$route.query.instant == "1") {
      this.logout();
    } else {
      this.isInstant = true;
    }
  },
  methods: {
    logout: async function () {
      const poolData = await this.$cog.poolData(
        this.$userPoolId,
        this.$clientId
      );
      const user = new CognitoUser({
        Username: this.email,
        Pool: poolData,
      });
      await user.signOut(() => {
        const locale = this.$route.query.lang;
        const callback = this.$route.query.srv;
        localStorage.removeItem("email");
        document.cookie = "wherexCookie" + "=" + "" + ";" + "5m" + ";path=/;domain=.wherex.com;";
        window.location = this.$utils.getHomeUrl(locale, callback);

      });
    },
  },
};
</script>

<style></style>
