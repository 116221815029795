<template>
  <div class="navegator-card" v-if="isBadNavegator">
    <div class="row">
      <div class="col-sm-1" id="icon" style="padding-right: 0;">
        <i class="fa-sharp fa-solid fa-circle-exclamation"></i>
      </div>
      <div class="col-sm-11" id="text" style="padding-left: 0;">
        <span v-html="$t('AlertNavegator')"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AlertNavegator",
  components: {},
  data() {
    return { isBadNavegator: false };
  },
  mounted() {
    let fontawesome = document.createElement("script");
    fontawesome.setAttribute(
      "src",
      "https://use.fontawesome.com/releases/v5.15.4/js/all.js"
    );
    document.head.appendChild(fontawesome);
    if (
      this.$utils.getBrowserInfo() != "Chrome" &&
      this.$utils.getBrowserInfo() != "Edge"
    ) {
      this.isBadNavegator = true;
    }
  },
};
</script>


<style>
@import "../assets/css/navegator.css";

@media only screen and (max-width: 600px) {

}
</style>