<template>
  <title>
    Wherex
  </title>
  <router-view />
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,681;1,681&display=swap" rel="stylesheet">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap"
    rel="stylesheet">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
    integrity="sha512-KfkfwYDsLkIlwQp6LFnl8zNdLGxu9YAA1QvwINks4PhcElQSvqcyVLLD9aMhXd13uQjoXtEKNosOWaZqXgel0g=="
    crossorigin="anonymous" referrerpolicy="no-referrer" />
</template>

<script>

export default {
  mounted() {
    const currentUrl = window.location.href;
    if (currentUrl.includes('landing')){
      document.body.style.display = "block";
      document.getElementById('app').setAttribute('style', 'display: block');
    }else{
      document.body.style.display = "flex";
    }

  },

};
</script>


<style>
@import "assets/css/bootstrap.min.css";


body {
  background: #F5F5F5;
  display: flex; /* Añadir display flex */
  align-items: center; /* Centra verticalmente */
  justify-content: center; /* Centra horizontalmente */
  text-align: center;
  min-height: 100vh; /* Asegura que el body ocupe toda la altura de la ventana */
  margin: 0; /* Elimina el margen por defecto */
}

#app {
  height: 100%;
  width: 100%;
  display: flex; /* Asegurar que también esté alineado si es necesario */
  align-items: center;
  justify-content: center;
}



</style>

