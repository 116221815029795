<template>
  <div class="login-bottom">
    <div id="rowBottom" class="row">
      <div id="colBottom" class="col">
        <div class="visit">
          <a href="https://www.wherex.com">{{ $t("VisitWherex") }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BottomCard",
  components: {},
};
</script>


<style>
@import "../assets/css/bottom.css";
</style>