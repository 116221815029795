<template>
  <!-- Caja Activa -->
  <div class="info-app-box" v-if="this.isEnabled">
    <div class="info-app-box-body">
      <div class="info-app-box-body-content">
        <div class="info-app-box-body-content-left">
          <img
            id="sytem-logo"
            class="img-fluid"
            :src="logo"
            alt="wherEX Sourcing"
          />
        </div>
        <div class="info-app-box-body-content-right">
          <p>
            <span v-html="$t('WherexSourcingLabel')"></span>
          </p>
        </div>
      </div>
      <div class="info-app-box-body-content mt-auto">
        <div class="info-app-box-body-content-left"></div>
        <div class="info-app-box-body-content-right">
          <div class="d-grid gap-2">
            <button class="btn btn-primary" id="system-button"  type="button" v-on:click="goSourcing">{{$t("SubmitLogin")}}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="info-app-box-alert info-app-box-alert-success">
      <div><i class="fa-solid fa-circle-check"></i></div>
      <div>{{$t("OkCompany")}}</div>
    </div>
  </div>
  <!-- Fin Caja Activa -->

  <!-- Caja desactiva -->
  <div class="info-app-box no-active" v-if="!this.isEnabled">
    <div class="info-app-box-body">
      <div class="info-app-box-body-content">
        <div class="info-app-box-body-content-left">
          <img
            class="img-fluid"
            :src="logo"
            alt="wherEX Sourcing"
          />
        </div>
        <div class="info-app-box-body-content-right">
          <p>
            <span v-html="$t('WherexSourcingLabel')"></span>
          </p>
        </div>
      </div>
      <div class="info-app-box-body-content mt-auto">
        <div class="info-app-box-body-content-left"></div>
        <div class="info-app-box-body-content-right">
          <div class="d-flex justify-content-left flex-column flex-md-row">
            <div class="me-3">
              <h4 id="sourcing-label">{{$t("LabelSourcing1")}}</h4>
              <a
                id="sourcing-button"
                class="btn btn-primary"
                v-bind:href="this.$sourcingInfo1"
                target="_blank"
                >{{$t("LabelRegisterSourcing1")}}</a
              >
            </div>
            <div>
              <h4 id="sourcing-label">{{$t("LabelSourcing2")}}</h4>
              <a
              id="sourcing-button"
                class="btn btn-primary"
                  v-bind:href="this.$sourcingInfo2"
                target="_blank"
                >{{$t("LabelRegisterSourcing2")}}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="footerSystem" class="info-app-box-alert info-app-box-alert-danger">
      <div><i class="fa-solid fa-circle-exclamation"></i></div>
      <div id="no-active-system-label">
        <span v-html="$t('NotOkCompany')"></span>
      </div>
    </div>
  </div>
  <!-- Fin Caja desactiva -->
</template>

<script>
import sourcingLogo from "../assets/images/wherex-logo.svg";
export default {
  components: {},
  props: {
    isEnabled: Boolean,
  },
  data: function () {
    return {
      logo: sourcingLogo
   };
  },
  mounted: function () {
  },
  methods:{
    goSourcing: function(){
       window.location.href = this.$utils.getRedirectUrl(
                  this.$sourcingLogin,
                  this.$local,
                  this.$clientId,
                  this.$userInfo.getEmail(this.$clientId),
                  this.$whiteList
                );
    }
  
  },

};
</script>

<style>
</style>