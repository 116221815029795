<template>
  <div id="main-container" class="container align-items-center justify-content-center">
    <div class="logo" style="margin-bottom: 26px;">
      <img class="img" :src="logo" alt="Wherex Logo" width="216" height="47" />
    </div>
    <div class="row align-items-center">
      <div class="col-sm-3"></div>
      <div class="col-sm-6">
        <ErrorCard />
      </div>
      <div class="col-sm-3"></div>
    </div>
    <div class="row align-items-center">
      <div class="col-sm-3"></div>
      <div class="col-sm-6"></div>
      <div class="col-sm-3"></div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import logo from "../assets/images/wherex-logo.svg";
import ErrorCard from "@/components/ErrorCard.vue";
export default {
  name: "Status",
  components: {
    ErrorCard,
  },
  mounted() {
  },
  data(){
    return{
      logo:logo
    }
  }
};
</script>

<style>
@import "../assets/css/table.css";
@import "../assets/css/copyright.css";
@import "../assets/css/mainContainer.css";
</style>