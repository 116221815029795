<template>
  <!-- Caja Activada -->
  <div class="info-app-box" v-if="this.isEnabled">
    <div class="info-app-box-body">
      <div class="info-app-box-body-content">
        <div class="info-app-box-body-content-left">
          <img :src="logo" alt="wherEX pay" id="pay-logo"/>
        </div>
        <div class="info-app-box-body-content-right">
          <p>
            <span v-html="$t('WherexPayLabel')"></span>
          </p>
        </div>
      </div>
      <div class="info-app-box-body-content mt-auto">
        <div class="info-app-box-body-content-left"></div>
        <div class="info-app-box-body-content-right">
          <div class="d-grid gap-2">
            <button id="pay-button" class="btn btn-primary" type="button" v-on:click="goPay">
              {{ $t("SubmitLogin") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="info-app-box-alert info-app-box-alert-success">
      <div><i class="fa-solid fa-circle-check"></i></div>
      <div>{{ $t("OkCompany") }}</div>
    </div>
  </div>
  <!-- Fin Activada  -->

  <!-- Caja Inactiva -->
  <div class="info-app-box no-active" v-if="!this.isEnabled">
    <div class="info-app-box-body">
      <div class="info-app-box-body-content">
        <div class="info-app-box-body-content-left">
          <img :src="logo" alt="wherEX pay" id="pay-logo"/>
        </div>
        <div class="info-app-box-body-content-right">
          <p>
            <span v-html="$t('WherexPayLabel')"></span>
          </p>
        </div>
      </div>
      <div class="info-app-box-body-content mt-auto">
        <div class="info-app-box-body-content-left"></div>
        <div class="info-app-box-body-content-right">
          <div class="d-grid gap-2">
            <button id="pay-button" class="btn btn-primary" type="button" v-on:click="goInfoPay">{{$t("ActivatePay")}}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="info-app-box-alert info-app-box-alert-danger" id="footerPay">
      <div> <i class="fa-solid fa-circle-exclamation"></i></div>
      <div id="no-active-pay-label" style="padding-left: 0px;">
        <span v-html="$t('PayTerm')"></span> <a id="pay-term-link" v-on:click="goPayTerms" href="#">{{ $t('PayTermLink') }}</a>
      </div>
    </div>
  </div>
  <!-- Caja inactiva  -->
</template>

<script>
import amplitude from "amplitude-js";
import logo from "../assets/images/wherex-pay-logo.svg";
export default {
  components: {},
  data: function () {
    return {
      logo: logo,
    };
  },
  props: {
    isEnabled: Boolean,
    isUserSystem:Boolean,
  },
  mounted() {},
  methods: {
    goPay: function () {
      let redirectUrl = this.$utils.getRedirectUrl(
        this.$payLogin,
        this.$local,
        this.$whiteList
      );

      let tokenForm = this.$utils.getTokenForm(redirectUrl, this.$clientId,this.$userInfo.getEmail(this.$clientId))
      document.getElementById("tokenForm").appendChild(tokenForm);
      tokenForm.submit();
    },
    goPayTerms: function () {
      window.open(this.$payTerms);
    },
    goInfoPay: function () {
      const email = this.$userInfo.getEmail(this.$clientId);

      if (this.$env == "PROD") {
        let eventProperties = { email: email };
        amplitude.getInstance().init("5ed9ed7f39125be524c67fff25d6263b");
        amplitude
          .getInstance()
          .logEvent("AmpLoginPayActivate", eventProperties);
      } else {
        let eventProperties = { email: email };
        amplitude.getInstance().init("fad566719c2099f78ef6175acb3c70a9");
        amplitude
          .getInstance()
          .logEvent("AmpLoginPayActivate", eventProperties);
      }
      if (this.isUserSystem == true){
        let redirectUrl = this.$utils.getRedirectUrl(
          this.$payOneClick,
          this.$local,
          this.$whiteList
        );

        let tokenForm = this.$utils.getTokenForm(redirectUrl, this.$clientId,this.$userInfo.getEmail(this.$clientId))
        document.getElementById("tokenForm").appendChild(tokenForm);
        tokenForm.submit();
      }else{
        window.open(this.$payInfo1);
      }
    },
  },
};
</script>

<style>
@import "../assets/css/landing.css";
</style>