<template>
  <br />
  <table class="login-table" @keyup.enter="loadingToLanding" v-if="isSucess">
    <tr>
      <td>
        <div id="TittleChangePasswordByCode" class="title-table">{{ $t("ResetPasswordTitle") }}</div>
      </td>
    </tr>
    <div class="sucess-table" v-if="isSucessResetPasswordAlert">
      <span v-html="$t('SucessResetPasswordAlert')"></span>
    </div>
    <tr>
      <td>
        <div class="form-group">
          <div id ="sucess-reset-password">
          {{ $t("SucessResetPasswordLabel") }}
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <div id="sumbit" class="form-group" style="margin-top: 8px;">
          <div class="row">
            <div class="col">
              <div class="d-flex justify-content-start flex-column flex-md-row" id="forgot-row">
                <button id="forgot-back" type="submit" class="btn btn-primary" v-on:click="loadingToLanding">
                  <div class="spinner-border spinner-border-sm text-light" role="status" v-if="isSpinnerLanding">
                    <span class="sr-only"></span>
                  </div>
                  <label v-if="!isSpinner">&nbsp;</label> {{ $t("SucessResetPasswordButton") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <br>
      </td>
    </tr>
  </table>
  <table class="login-table" @keyup.enter="loadingChange" v-if="!isSucess">
    <tr>
      <td>
        <div id="TittleChangePasswordByCode" class="title-table">{{ $t("ResetPasswordTitle") }}</div>
      </td>
    </tr>
    <tr>
      <td>
        <div class="error-table" v-if="isResendEmailBad">
          {{ $t("ServiceNotAvaible") }}
        </div>
        <div class="error-table" v-if="isCannotBeSend">
          {{ $t("IsCannotBeReset") }}
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <div class="sucess-table" v-if="!isSpinner2 && !isResendEmailBad">
          <span v-html="$t('SuccesSendEmail')"></span>
        </div>
        <div class="text-center" v-if="isSpinner2">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <div class="form-group">
          <div id ="forgot-text">
            <span v-html="$t('ChangePasswordCodeLabel')"></span> <u><strong><a v-on:click="reSendEmail(this.email)"
              class="resend-email" href="#">{{ $t("ReSendEmail") }}</a></strong></u>
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <div id="label" class="form-group">
          <label class="LabelChangePasswordByCode" for="Code">{{ $t("Code") }}</label>
          <input id="inputCode" type="text" class="form-control" v-on:click="cleanInputCode" v-model="code" />
          <small class="error-empty" v-if="isEmptyCode">
            {{ $t("RequiredField") }}
          </small>
          <div class="sub-label">
            {{ $t("CodeSubLabel") }}
          </div>
        </div>
        <div id="label" class="form-group">
          <label class="LabelChangePasswordByCode" for="Password">{{ $t("NewPasswordLabel") }}</label>
          <div class="input-group">
            <input id="inputPassword" type="password" class="form-control" v-on:click="cleanInputPassword"
              v-model="password" />
            <span style="cursor: pointer" v-on:click="showPassword('inputPassword', 'show-password')"
              class="input-group-text" id="show-password"><i class="fa fa-eye"></i></span>
          </div>
          <div class="sub-label">
            {{ $t("PasswordSubLabel") }}
          </div>
          <small class="error-empty" v-if="isEmptyPassword">
            {{ $t("RequiredField") }}
          </small>
        </div>
        <div id="label" class="form-group">
          <label class="LabelChangePasswordByCode" for="Password">{{ $t("RepeatNewPasswordLabel") }}</label>
          <div class="input-group mb-3">
            <input id="inputRepeatPassword" type="password" class="form-control" v-on:click="cleanInputRepeatPassword"
              v-model="repeatPassword" />
            <span style="cursor: pointer" v-on:click="
              showPassword('inputRepeatPassword', 'show-repeat-password')
            " class="input-group-text" id="show-repeat-password"><i class="fa fa-eye"></i></span>
          </div>
          <small class="error-empty" v-if="isEmptyRepeatPassword">
            {{ $t("RequiredField") }}
          </small>
        </div>
        <div class="error-table" v-if="isError">
          {{ $t("PasswordsDoNotMatch") }}
        </div>
        <div class="error-table" v-if="isBadCode">
          {{ $t("CodeIsNotCorrect") }}
        </div>
        <div class="error-table" v-if="isExpiredCode">
          {{ $t("ExpiredCode") }}
        </div>
        <div class="weak-table" v-if="isBadPassword">
          {{ $t("WeakPassword") }}
          <ul>
            <li>{{ $t("LengthPassword") }}</li>
            <li>{{ $t("NeedNumber") }}</li>
            <li>{{ $t("NeedSpecialCharacter") }}</li>
            <li>{{ $t("NeedCapitalized") }}</li>
            <li>{{ $t("NeedLowerCase") }}</li>
          </ul>
        </div>
        <div class="error-table" v-if="isLimitExceeded">
          {{ $t("LimitExceeded") }}
        </div>
        <div id="sumbit" class="form-group">
          <button id="loginEnter" type="submit" class="btn btn-lg btn-primary btn-block" v-on:click="loadingChange">
            <div class="spinner-border spinner-border-sm text-light" role="status" v-if="isSpinner">
              <span class="sr-only"></span>
            </div>
            <label v-if="!isSpinner">&nbsp;</label> {{ $t("ChangePassword") }}
          </button>
        </div>
        <div class="forgot-table">
          <div id="back">
            <a v-on:click="goToHome">{{ $t("Back") }}</a>
          </div>
        </div>
        <BottomCard />
      </td>
    </tr>
  </table>
</template>
<script>
import BottomCard from "@/components/BottomCard.vue";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";

export default {
  components: {
    BottomCard,
  },
  data() {
    var email = this.$route.query.email;
    if (email == undefined) {
      email = "";
    }
    return {
      code: "",
      repeatPassword: "",
      password: "",
      email: email,
      isHidden: false,
      isError: false,
      isSpinner: false,
      isBadEmail: false,
      isEmptyEmail: false,
      isNotSucess: false,
      isBadServices: false,
      isEmptyCode: false,
      isEmptyPassword: false,
      isEmptyRepeatPassword: false,
      isBadCode: false,
      isBadPassword: false,
      isLimitExceeded: false,
      isSpinner2: false,
      isResendEmailBad: false,
      isCannotBeSend: false,
      isSucess: false,
      isSpinnerLanding: false,
      isSucessResetPasswordAlert:true,
      isExpiredCode:false,
    };
  },
  methods: {
    goLanding: async function () {
      var locale = this.$route.query.lang;
      window.location.href = this.$utils.getLandingUrl(locale, "")
    },
    loadingToLanding:  async function () {
      this.isSpinnerLanding = true;
      await this.sleep(1000)
      await this.goLanding().then
     
    },
    loadingChange: async function () {
      this.isSpinner = true;
      await this.changePassword();
    },
    changePassword: async function () {
      this.code = this.code.trim()
      this.password = this.password.trim()
      this.repeatPassword = this.repeatPassword.trim()
      this.email = this.email.trim()
      this.isError = false;
      document
        .getElementById("inputCode")
        .style.setProperty("border-color", "#e5e6e8");
      document
        .getElementById("inputPassword")
        .style.setProperty("border-color", "#e5e6e8");
      document
        .getElementById("inputRepeatPassword")
        .style.setProperty("border-color", "#e5e6e8");
      this.isBadCode = false;
      this.isBadPassword = false;
      if (this.code == "" || this.code == undefined) {
        this.isEmptyCode = true;
        this.isSpinner = false;
        this.isError = false;
        document
          .getElementById("inputCode")
          .style.setProperty("border-color", "#dc3545");
      } else {
        this.isEmptyCode = false;
      }
      if (this.password == "" || this.password == undefined) {
        this.isEmptyPassword = true;
        this.isSpinner = false;
        this.isError = false;
        document
          .getElementById("inputPassword")
          .style.setProperty("border-color", "#dc3545");
      } else {
        this.isEmptyPassword = false;
      }
      if (this.repeatPassword == "" || this.repeatPassword == undefined) {
        this.isEmptyRepeatPassword = true;
        this.isSpinner = false;
        this.isError = false;
        document
          .getElementById("inputRepeatPassword")
          .style.setProperty("border-color", "#dc3545");
      } else if (this.password != this.repeatPassword) {
        this.isEmptyPassword = false;
        this.isBadPassword = false;
        this.isError = true;
        this.isSpinner = false;
        this.isNotSucess = false;
      } else if (
        this.code != "" &&
        this.code != undefined &&
        this.password != "" &&
        this.password != undefined &&
        this.repeatPassword != "" &&
        this.repeatPassword != undefined
      ) {
        this.isEmptyRepeatPassword = false;
        this.isEmptyPassword = false;
        this.isEmptyCode = false;

        document
          .getElementById("inputCode")
          .style.setProperty("border-color", "#e5e6e8");
        document
          .getElementById("inputPassword")
          .style.setProperty("border-color", "#e5e6e8");
        document
          .getElementById("inputRepeatPassword")
          .style.setProperty("border-color", "#e5e6e8");
        const poolData = await this.$cog.poolData(
          this.$userPoolId,
          this.$clientId
        );
        const user = new CognitoUser({
          Username: this.email,
          Pool: poolData,
        });
        user.confirmPassword(this.code, this.password, {
          onSuccess: async () => {
            this.isError = false;
            this.isBadCode = false;
            this.isBadPassword = false;

            user.setAuthenticationFlowType("USER_PASSWORD_AUTH");
            const authDetails = new AuthenticationDetails({
              Username: this.email,
              Password: this.password,
            });
            user.authenticateUser(authDetails, {
              onSuccess: () => {
                document.cookie = "wherexCookie" + "=" + this.$userInfo.getAcces(this.$clientId,this.email) + ";" + "5m" + ";path=/;domain=.wherex.com;";
                localStorage.setItem("email", this.email);
                this.isSucess = true;
              },
              onFailure: () => {
                var locale = this.$route.query.lang;
                window.location.href = this.$utils.getHomeUrl(locale, "")
              },
              newPasswordRequired: () => {
                var locale = this.$route.query.lang;
                window.location.href = this.$utils.getHomeUrl(locale, "")
              }
            })
              ;
          },
          onFailure: (err) => {
            if (err.code == "InvalidPasswordException") {
              this.isBadPassword = true;
              this.isNotSucess = true;
              this.isSpinner = false;
            } else if (err.code == "InvalidParameterException") {
              this.isBadCode = true;
              this.isNotSucess = true;
              this.isSpinner = false;
              this.isEmptyRepeatPassword = false;
            } else if (err.code == "CodeMismatchException") {
              this.isBadCode = true;
              this.isNotSucess = true;
              this.isSpinner = false;
              this.isEmptyRepeatPassword = false;
            } else if (err.code == "ExpiredCodeException") {
              this.isExpiredCode=true;
              this.isBadCode = false;
              this.isNotSucess = false;
              this.isSpinner = false;
              this.isBadPassword = false;
              this.isEmptyRepeatPassword = false;
            } else if (
              err.message.includes("Password does not conform to policy:")
            ) {
              this.isBadPassword = true;
            } else if (
              err.message ==
              "Attempt limit exceeded, please try after some time."
            ) {
              this.isLimitExceeded = true;
              this.isSpinner = false;
            } else {
              this.isError = true;
              this.isNotSucess = true;
              this.isSpinner = false;
            }
          },
        });
      } else {
        this.isSpinner = false;
      }
    },
    goToHome: function () {
      var locale = this.$i18n.locale;
      var callback = this.$route.query.srv;
      window.location.href = this.$utils.getHomeUrl(locale, callback);
    },
    sleep: async function (ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    cleanInputPassword: async function () {
      this.isEmptyPassword = false;
      document
        .getElementById("inputPassword")
        .style.setProperty("border-color", "#e5e6e8");
    },
    cleanInputRepeatPassword: async function () {
      this.isEmptyRepeatPassword = false;
      document
        .getElementById("inputRepeatPassword")
        .style.setProperty("border-color", "#e5e6e8");
    },
    cleanInputCode: async function () {
      this.isEmptyCode = false;
      document
        .getElementById("inputCode")
        .style.setProperty("border-color", "#e5e6e8");
    },
    cleanInputEmail: async function () {
      this.isBadEmail = false;
      this.isEmptyEmail = false;
      document
        .getElementById("inputEmail")
        .style.setProperty("border-color", "#e5e6e8");
    },
    showPassword: async function (idInput, idAddons) {
      if (document.getElementById(idInput).type == "text") {
        document.getElementById(idInput).type = "password";
        document.getElementById(idAddons).innerHTML =
          '<i class="fa fa-eye" aria-hidden="true"></i>';
      } else {
        document.getElementById(idInput).type = "text";
        document.getElementById(idAddons).innerHTML =
          '<i class="fa fa-eye-slash" aria-hidden="true"></i>';
      }
    },
    reSendEmail: async function (email) {
      this.isSucessResetPasswordAlert = true;
      this.isSpinner2 = true;
      this.isResendEmailBad = false;
      this.isCannotBeSend = false;
      const poolData = await this.$cog.poolData(
        this.$userPoolId,
        this.$clientId
      );
      const user = new CognitoUser({
        Username: email,
        Pool: poolData,
      });
      await user.forgotPassword({
        onSuccess: async () => {
          this.isSpinner2 = false;
        },
        onFailure: async (err) => {
          await this.$log.sendLog(this.$logUrl,this.email,err.message);
          this.isSpinner2 = false;
          this.isSucessResetPasswordAlert = false;
          if (err.message == "User password cannot be reset in the current state.") {
            this.isCannotBeSend = true          
          } else {
            this.isResendEmailBad = true;
          }
        },
      });
    },
  },
  mounted() {
    const emailParameter = this.$route.query.email;
    if (emailParameter == undefined || emailParameter == "undefined") {
      const url = this.$route.query.srv;
      const locale = this.$route.query.lang;
      window.location.href = this.$utils.getHomeUrl(locale, url);
    } else {
      this.email = emailParameter;
    }
  },
};
</script>

<style>
@import "../assets/css/table.css";
</style>