<template>
  <table class="login-table" v-if="!isHidden" @keyup.enter="loadingSend">
    <tr>
      <td>
        <div class="title-table">{{ $t("ForgotCardTitle") }}</div>
      </td>
    </tr>
    <tr>
      <td>
        <div class="form-group">
          <div id="forgot-text">
          {{ $t("ForgotText") }}
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <div id="label" class="form-group">
          <label for="Email">Email</label>
          <input
            id="inputEmail"
            type="email"
            class="form-control"
            v-model="email"
            v-on:click="cleanInputEmail"
            aria-describedby="emailHelp"
          />
          <small class="error-empty" v-if="isEmptyEmail">
            {{ $t("RequiredField") }}
          </small>
          <small class="error-empty" v-if="isBadEmail">
            {{ $t("InvalidEmail") }}
          </small>
        </div>
        <div class="error-table" v-if="isBadServices">
          {{ $t("ServiceNotAvaible") }}
        </div>
        <div class="error-table" v-if="isCannotBeReset">
          {{ $t("IsCannotBeReset") }}
        </div>
        <div class="error-table" v-if="isUserNotFound">
          {{ $t("UserNotFound") }}
        </div>
        <br v-if="isBadServices" />
        <br v-if="isSucess" />
        <div class="row">
          <div class="col">
            <div class="d-flex justify-content-end flex-column flex-md-row" id="forgot-row">
              <div class="back-table">
                  <a v-on:click="goToHome">{{ $t("Back") }}</a>
              </div>
              <button
                id="forgotEnter"
                type="submit"
                class="btn btn-primary"
                v-on:click="loadingSend"
              >
                <div
                  class="spinner-border spinner-border-sm text-light"
                  role="status"
                  v-if="isSpinner"
                >
                  <span class="sr-only"></span>
                </div>
                <label v-if="!isSpinner">&nbsp;</label> {{ $t("Submit") }}
              </button>
            </div>
          </div>
        </div>
      </td>
    </tr>
    <br />
  </table>
</template>
<script>
import { CognitoUser } from "amazon-cognito-identity-js";

export default {
  components: {},
  data() {
    return {
      isEmptyEmail: false,
      isHidden: false,
      isError: false,
      isSpinner: false,
      isBadEmail: false,
      isSuccess: false,
      isNotSucess: false,
      isBadServices: false,
      isUserNotFound: false,
      isSucess: false,
      email: "",
      isCannotBeReset: false,
    };
  },
  methods: {
    forgot: async function () {
      this.email = this.email.trim();
      this.cleanAllDiv();
      const poolData = await this.$cog.poolData(
        this.$userPoolId,
        this.$clientId
      );
      this.isSpinner = true;
      if (this.email == undefined || this.email == "") {
        this.isEmptyEmail = true;
        this.isSpinner = false;
        document
          .getElementById("inputEmail")
          .style.setProperty("border-color", "#dc3545");
      } else if (!this.$utils.validateEmail(this.email)) {
        this.isBadEmail = true;
        this.isSpinner = false;
        document
          .getElementById("inputEmail")
          .style.setProperty("border-color", "#dc3545");
      } else {
        document
          .getElementById("inputEmail")
          .style.setProperty("border-color", "#e5e6e8");
        const user = new CognitoUser({
          Username: this.email,
          Pool: poolData,
        });
        user.forgotPassword({
          onSuccess: async () => {
            this.isSucess = true;
            this.isBadServices = false;
            var locale = this.$i18n.locale;
            var url = this.$route.query.srv;
            window.location.href = this.$utils.getChangeUrl(
              locale,
              url,
              this.email
            );
          },
          onFailure: async (err) => {
            this.isSucess = false;
            this.isSpinner = false;
            if (err.code == "UserNotFoundException") {
              this.isUserNotFound = true;
            } else if (err.code == "NotAuthorizedException") {
              await this.$log.sendLog(this.$logUrl,this.email,err.message);
              if (
                err.message ==
                "User password cannot be reset in the current state."
              ) {
                this.isCannotBeReset = true;
              }
            } else {
              await this.$log.sendLog(this.$logUrl,this.email,err.message);
              this.isBadServices = true;
            }
          },
        });
      }
    },
    sleep: async function (ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    loadingSend: async function () {
      this.isSpinner = true;
      await this.forgot();
    },
    cleanInputEmail: async function () {
      this.isBadEmail = false;
      this.isEmptyEmail = false;
      document
        .getElementById("inputEmail")
        .style.setProperty("border-color", "#e5e6e8");
    },
    goToHome: function () {
      var locale = this.$i18n.locale;
      var callback = this.$route.query.srv;
      window.location.href = this.$utils.getHomeUrl(locale, callback);
    },
    goToChange: function (emailAux) {
      var locale = this.$i18n.locale;
      var callback = this.$route.query.srv;
      window.location.href = this.$utils.getChangeUrl(
        locale,
        callback,
        emailAux
      );
    },
    cleanAllDiv: function () {
      this.isEmptyEmail = false;
      this.isHidden = false;
      this.isError = false;
      this.isBadEmail = false;
      this.isSuccess = false;
      this.isNotSucess = false;
      this.isBadServices = false;
      this.isSucess = false;
      this.isUserNotFound = false;
      this.isCannotBeReset = false;
    },
  },
  mounted() {},
};
</script>

<style>
@import "../assets/css/table.css";
</style>