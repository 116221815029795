<template>
<!-- End Top Bar -->
<div class="container g-0 mt-0" style="padding-top: 29px;">     
    <div class="row">
        <div class="col">
        <div class="d-flex justify-content-center flex-column flex-md-row">
          <SystemCard  v-bind:isEnabled="this.sourcing" />
          <Fintechcard v-bind:isEnabled="this.pay" v-bind:isUserSystem="this.sourcing"/>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import SystemCard from "@/components/SystemCard.vue";
import Fintechcard from "@/components/FintechCard.vue";
export default {
  components:{SystemCard,Fintechcard},
  props:{
    sourcing:Boolean,
    pay:Boolean,
  },
  mounted: async function(){

  }
}
</script>

<style>
@import "../assets/css/table.css";
</style>