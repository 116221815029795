<template>
  <table class="login-table" @keyup.enter="loadingChange">
    <tr>
      <td>
        <div class="title-table">{{ $t("ChangeTitle") }}</div>
      </td>
    </tr>
    <tr>
      <td>
        <div id="label" class="form-group">
          <label for="OldPassword">{{ $t("OldPassword") }}</label>
          <div class="input-group">
            <input
              id="inputOldPassword"
              type="password"
              class="form-control"
              v-on:click="cleanInputOldPassword"
              v-model="oldPassword"
            /> <span
            style="cursor: pointer;"
              v-on:click='showPassword("inputOldPassword","show-old-password")'
              class="input-group-text"
              id="show-old-password"
              ><i class="fa fa-eye"></i
            ></span>
          </div>
          <small class="error-empty" v-if="isEmptyOldPassword">
            {{ $t("RequiredField") }}
          </small>
        </div>
        <div id="label" class="form-group">
          <label for="Password">{{ $t("NewPasswordLabel") }}</label>
          <div class="input-group">
            <input
              id="inputPassword"
              type="password"
              class="form-control"
              v-on:click="cleanInputPassword"
              v-model="password"
            /><span
            style="cursor: pointer;"
              v-on:click='showPassword("inputPassword","show-password")'
              class="input-group-text"
              id="show-password"
              ><i class="fa fa-eye"></i
            ></span>
          </div>
          <small class="error-empty" v-if="isEmptyPassword">
            {{ $t("RequiredField") }}
          </small>
        </div>
        <div id="label" class="form-group">
          <label for="Password">{{ $t("RepeatNewPasswordLabel") }}</label>
          <div class="input-group">
            <input
              id="inputRepeatPassword"
              type="password"
              class="form-control"
              v-on:click="cleanInputRepeatPassword"
              v-model="repeatPassword"
            /><span
            style="cursor: pointer;"
              v-on:click='showPassword("inputRepeatPassword","show-repeat-password")'
              class="input-group-text"
              id="show-repeat-password"
              ><i class="fa fa-eye"></i
            ></span>
          </div>
          <small class="error-empty" v-if="isEmptyRepeatPassword">
            {{ $t("RequiredField") }}
          </small>
        </div>
        <div class="error-table" v-if="isError">
          {{ $t("PasswordsDoNotMatch") }}
        </div>
        <div class="error-table" v-if="isBadOldPassword">
          {{ $t("BadPassword") }}
        </div>
        <div class="weak-table" v-if="isBadPassword">
          {{ $t("WeakPassword") }}
          <br />
          <ul>
            <li>{{ $t("LengthPassword") }}</li>
            <li>{{ $t("NeedNumber") }}</li>
            <li>{{ $t("NeedSpecialCharacter") }}</li>
            <li>{{ $t("NeedCapitalized") }}</li>
            <li>{{ $t("NeedLowerCase") }}</li>
          </ul>
        </div>
        <div class="sucess-table" v-if="isSuccess">
          {{ $t("SucessChangePassword") }}
        </div>
        <div class="error-table" v-if="isLimitExceeded">
          {{ $t("LimitExceeded") }}
        </div>

        <div id="sumbit" class="form-group">
          <button
            id="loginEnter"
            type="submit"
            class="btn btn-lg btn-primary btn-block"
            v-on:click="loadingChange"
          >
            <div
              class="spinner-border spinner-border-sm text-light"
              role="status"
              v-if="isSpinner"
            >
              <span class="sr-only"></span>
            </div>
            <label v-if="!isSpinner">&nbsp;</label> {{ $t("ChangePassword") }}
          </button>
        </div>
        <div class="forgot-table">
          <div id="back">
            <a v-on:click="goToHome">{{ $t("Back") }}</a>
          </div>
        </div>
        <BottomCard />
      </td>
    </tr>
  </table>
</template>
<script>
import BottomCard from "@/components/BottomCard.vue";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";

export default {
  components: {
    BottomCard,
  },
  data() {
    var email = this.$userInfo.getEmail(this.$clientId);
    if (email == undefined) {
      email = "";
    }
    return {
      oldPassword: "",
      repeatPassword: "",
      password: "",
      email: email,
      originalEmail: "",
      isPersonify: false,
      isHidden: false,
      isError: false,
      isSpinner: false,
      isBadEmail: false,
      isEmptyEmail: false,
      isSuccess: false,
      isNotSucess: false,
      isBadServices: false,
      isEmptyOldPassword: false,
      isEmptyPassword: false,
      isEmptyRepeatPassword: false,
      isBadOldPassword: false,
      isBadPassword: false,
      isLimitExceeded: false,
    };
  },
  methods: {
    loadingChange: async function () {
      this.isSpinner = true;
      await this.changePassword();
    },
    changePassword: async function () {
      this.oldPassword = this.oldPassword.trim();
      this.repeatPassword = this.repeatPassword.trim();
      this.password = this.password.trim();
      this.originalEmail = this.originalEmail.trim();
      this.resetAllDivs();
      document
        .getElementById("inputOldPassword")
        .style.setProperty("border-color", "#e5e6e8");
      document
        .getElementById("inputPassword")
        .style.setProperty("border-color", "#e5e6e8");
      document
        .getElementById("inputRepeatPassword")
        .style.setProperty("border-color", "#e5e6e8");
      this.isBadOldPassword = false;
      this.isBadPassword = false;
      if (this.oldPassword == "" || this.oldPassword == undefined) {
        this.isEmptyOldPassword = true;
        this.isSpinner = false;
        document
          .getElementById("inputOldPassword")
          .style.setProperty("border-color", "#dc3545");
      }
      if (this.password == "" || this.password == undefined) {
        this.isEmptyPassword = true;
        this.isSpinner = false;
        document
          .getElementById("inputPassword")
          .style.setProperty("border-color", "#dc3545");
      }
      if (this.repeatPassword == "" || this.repeatPassword == undefined) {
        this.isEmptyRepeatPassword = true;
        this.isSpinner = false;
        document
          .getElementById("inputRepeatPassword")
          .style.setProperty("border-color", "#dc3545");
      } else if (this.password != this.repeatPassword) {
        this.isError = true;
        this.isSpinner = false;
      } else if (
        this.oldPassword != "" &&
        this.oldPassword != undefined &&
        this.password != "" &&
        this.password != undefined &&
        this.repeatPassword != "" &&
        this.repeatPassword != undefined
      ) {
        document
          .getElementById("inputOldPassword")
          .style.setProperty("border-color", "#e5e6e8");
        document
          .getElementById("inputPassword")
          .style.setProperty("border-color", "#e5e6e8");
        document
          .getElementById("inputRepeatPassword")
          .style.setProperty("border-color", "#e5e6e8");
        const poolData = await this.$cog.poolData(
          this.$userPoolId,
          this.$clientId
        );
        const user = new CognitoUser({
          Username: this.email,
          Pool: poolData,
        });
        const authDetails = new AuthenticationDetails({
          Username: this.email,
          Password: this.oldPassword,
        });
        user.authenticateUser(authDetails, {
          onSuccess: () => {
"wherexCookie" + "=" + this.$userInfo.getAcces(this.$clientId,this.email) + ";" + "5m" + ";path=/;domain=.wherex.com;"
            user.changePassword(
              this.oldPassword,
              this.password,
              async (error) => {
                if (error) {
                  if (error.code == "LimitExceededException") {
                    this.isLimitExceeded = true;
                  } else {
                    this.isBadPassword = true;
                  }
                  this.isSpinner = false;
                } else {
                  this.isSpinner = false;
                  this.isSuccess = true;
                  await this.sleep(3000);
                  if (this.isPersonify == true) {
                    user.signOut();
                    this.email = this.originalEmail;
                    localStorage.setItem("CognitoIdentityServiceProvider."+this.$clientId+".LastAuthUser", this.email);
                  }
                  window.location.href = this.$utils.getRedirectUrl(
                    this.$route.query.srv,
                    this.$local,
                    this.$clientId,
                    this.email,
                    this.$whiteList
                  );
                }
              }
            );
          },
          onFailure: () => {
            this.isSpinner = false;
            this.isBadOldPassword = true;
          },
          newPasswordRequired: () => {
            this.isSpinner = false;
            this.isBadOldPassword = true;
          },
        });
      } else {
        this.isSpinner = false;
      }
    },
    goToHome: function () {
      var locale = this.$i18n.locale;
      var callback = this.$route.query.srv;
      window.location.href = this.$utils.getHomeUrl(locale, callback);
    },
    sleep: async function (ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    cleanInputPassword: async function () {
      this.isEmptyPassword = false;
      document
        .getElementById("inputPassword")
        .style.setProperty("border-color", "#e5e6e8");
    },
    cleanInputRepeatPassword: async function () {
      this.isEmptyRepeatPassword = false;
      document
        .getElementById("inputRepeatPassword")
        .style.setProperty("border-color", "#e5e6e8");
    },
    cleanInputOldPassword: async function () {
      this.isEmptyOldPassword = false;
      document
        .getElementById("inputOldPassword")
        .style.setProperty("border-color", "#e5e6e8");
    },
    cleanInputEmail: async function () {
      this.isBadEmail = false;
      this.isEmptyEmail = false;
      document
        .getElementById("inputEmail")
        .style.setProperty("border-color", "#e5e6e8");
    },
    resetAllDivs: async function () {
      this.isHidden = false;
      this.isError = false;
      this.isBadEmail = false;
      this.isEmptyEmail = false;
      this.isSuccess = false;
      this.isNotSucess = false;
      this.isBadServices = false;
      this.isEmptyOldPassword = false;
      this.isEmptyPassword = false;
      this.isEmptyRepeatPassword = false;
      this.isBadOldPassword = false;
      this.isBadPassword = false;
      this.isLimitExceeded = false;
    },
    showPassword: async function (idInput,idAddons) {
      if (document.getElementById(idInput).type == "text") {
        document.getElementById(idInput).type = "password";
        document.getElementById(idAddons).innerHTML =
          '<i class="fa fa-eye" aria-hidden="true"></i>';
      } else {
        document.getElementById(idInput).type = "text";
        document.getElementById(idAddons).innerHTML =
          '<i class="fa fa-eye-slash" aria-hidden="true"></i>';
      }
    },
  },
  mounted() {
    if (this.$route.query.email != "" && this.$route.query.srv != undefined) {
      this.originalEmail = this.email;
      this.email = this.$route.query.email;
      this.isPersonify = true;
    }
  },
};
</script>

<style>
@import "../assets/css/table.css";
</style>