<template>
  <div id="main-container" class="container-fluid align-items-center justify-content-center">
    <div class="logo" style="margin-bottom: 26px;">
      <img class="img" :src="logo" alt="Wherex Logo" width="216" height="47" />
    </div>
    <div class="row align-items-center" v-if="!isLogin">
      <div class="col">
        <br>
        <br>
        <div id="spinner-login" class="spinner-border" role="status"></div>
      </div>
    </div>
    <div class="row align-items-center" v-if="isLogin">
      <div class="col-sm-3"></div>
      <div class="col-sm-6">
        <AlertNavegator />
      </div>
      <div class="col-sm-3"></div>
    </div>
    <div class="row align-items-center" v-if="isLogin">
      <div class="col-sm-3"></div>
      <div class="col-sm-6">
        <LoginCard />
      </div>
      <div class="col-sm-3"></div>
    </div>

    <div class="row" v-if="isLogin">
      <div class="col-sm-3"></div>
      <div id="changeLocaleZone" class="col-sm-3">
        <ChangeLocale />
      </div>
      <div class="col-sm-3">
        <div id="copyrightZone" class="copyright">
          <Copyright />
        </div>
      </div>
      <div class="col-sm-3"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import logo from "../assets/images/wherex-logo.svg";
import LoginCard from "@/components/LoginCard.vue";
import AlertNavegator from "@/components/AlertNavegator.vue";
import ChangeLocale from "@/components/ChangeLocale.vue";
import Copyright from "@/components/Copyright.vue";
import { CognitoUser, CognitoRefreshToken } from "amazon-cognito-identity-js";
export default {
  name: "Home",
  components: {
    LoginCard,
    AlertNavegator,
    ChangeLocale,
    Copyright
  },
  data() {
    return {
      isBadNavegator: false,
      isLogin: false,
      logo: logo,
    };
  },
  async mounted() {
    this.isLogin = !this.$userInfo.isLogin(this.$clientId);
    if (this.$userInfo.isLogin(this.$clientId)) {
      const url = this.$route.query.srv;
      const locale = this.$route.query.lang;
      const email = this.$userInfo.getEmail(this.$clientId);
      var poolData = this.$cog.poolData(this.$userPoolId, this.$clientId);
      var user = new CognitoUser({
        Username: this.$userInfo.getEmail(this.$clientId),
        Pool: poolData,
      });
      var token = new CognitoRefreshToken({
        RefreshToken: this.$userInfo.getRefresh(this.$clientId, email),
      });
      await user.refreshSession(token, (err) => {
        if (err != null) {
          localStorage.removeItem("email");
          document.cookie = "wherexCookie" + "=" + "" + ";" + "5m" + ";path=/";
          user.signOut();
          window.location.href = this.$utils.getHomeUrl(locale, url);
        } else {
          if (url != undefined || url != "") {
            document.cookie = "wherexCookie" + "=" + this.$userInfo.getAcces(this.$clientId, this.$userInfo.getEmail(this.$clientId)) + ";" + "5m" + ";path=/;domain=.wherex.com";
            window.location.href = window.location.href =
              this.$utils.getRedirectUrl(url, this.$local, this.$clientId, email, this.$whiteList);
          } else {
            document.cookie = "wherexCookie" + "=" + this.$userInfo.getAcces(this.$clientId, this.$userInfo.getEmail(this.$clientId)) + ";" + "5m" + ";path=/;domain=.wherex.com;";
            window.location.href = this.$utils.getLandingUrl(locale, url);
          }
        }
      });
    }
  },
};
</script>
<style>
@import "../assets/css/copyright.css";
@import "../assets/css/changeLocaleZone.css";
@import "../assets/css/mainContainer.css";



#spinner-login {
  color: #1f3663;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}




</style>
