<template>
  <div id="main-container" class="container align-items-center justify-content-center">
    <div class="logo" style="margin-bottom: 26px;">
      <img class="img" :src="logo" alt="Wherex Logo" width="216" height="47" />
    </div>
    <div class="row align-items-center">
      <div class="col-sm-3"></div>
      <div class="col-sm-6">
        <DirectChangePasswordCard />
      </div>
      <div class="col-sm-3"></div>
    </div>
    <div class="row">
      <div class="col-sm-3"></div>
      <div id="changeLocaleZone" class="col-sm-3">
        <ChangeLocale />
      </div>
      <div class="col-sm-3">
        <div id="copyrightZone" class="copyright"><Copyright /></div>
      </div>
      <div class="col-sm-3"></div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import logo from "../assets/images/wherex-logo.svg";

import DirectChangePasswordCard from "@/components/DirectChangePasswordCard.vue";
import ChangeLocale from "@/components/ChangeLocale.vue";
import Copyright from "@/components/Copyright.vue";

export default {
  name: "Change",
  components: {
    DirectChangePasswordCard,
    ChangeLocale,
    Copyright
  },
  data() {
    return{
      logo:logo,
    }
  },
  mounted() {
    if (!this.$userInfo.isLogin(this.$clientId)) {
      const url = this.$route.query.srv;
      const locale = this.$route.query.lang;
      window.location.href = this.$utils.getHomeUrl(locale, url);
    }
  },
};
</script>
<style>
@import "../assets/css/copyright.css";
@import "../assets/css/changeLocaleZone.css";
@import "../assets/css/mainContainer.css";
.container {
  margin-top: 100px;
}

</style>
