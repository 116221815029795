<template>
  <h1></h1>
</template>

<script>
export default {
  name: "NotFoundComponent",
  mounted() {
    var destination = window.location.href.replace(window.location.origin+"/#/","")
    var destinationNotParameters  = destination.split("?")
    const url = this.$route.query.srv;
    const locale = this.$route.query.lang;
    const email = this.$route.query.email;
    switch(destinationNotParameters[0]){
      case "logout":
        window.location.href = this.$utils.getLogOutUrl(locale,url);
        break;
      case "forgot":
        window.location.href = this.$utils.getForgotUrl(locale,url);
        break;
      case "change":
        window.location.href = this.$utils.getChangeUrl(locale,url,email);
        break;
      default:
        window.location.href = this.$utils.getHomeUrl(locale,url)
        break;
    }
  },
};
</script>

<style>
.return {
  text-align: right;
  margin-top: 0.2cm;
  margin-right: 0.5cm;
}

.singUp {
  text-align: end;
}

.visit {
  text-align: start;
}

.card-title {
  margin-top: 5px;
  margin-bottom: 10px;
  color: #7f7f7f;
  font-size: 26px;
}

.card {
  margin-top: 2ch;
  text-align: center;
  width: 100%;
  height: 100%;
  background: #f9f9f9;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.card-body {
  background: #f9f9f9;
}

.main-table {
  width: 100%;
  height: 100%;
}

.labelitem {
  margin-top: 5px;
  margin-bottom: 5px;
}

#forgot {
  font-size: 13px;
  color: #09aadb;
  text-align: center;
  text-decoration: none;
  background-color: #f9f9f9;
}

#return {
  text-decoration: none;
  font-size: 13px;
  color: #09aadb;
  margin-top: -10px;
  margin-bottom: -20px;
  text-align: center;
  text-decoration: none;
}

#login-body {
  text-align: left;
  background: #f9f9f9;
  color: #7f7f7f;
}

#label {
  font-size: 13px;
  font-weight: bold;
  color: #7f7f7f;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
}

#sumbit {
  margin-top: 20px;
  text-align: center;
  width: 100%;
  height: 100%;
  vertical-align: baseline;
}

#loginEnter {
  width: 100%;
  background: #09aadb;
  border-color: #09aadb;
  text-align: center;
}

#forgotEnter {
  background: #09aadb;
  border-color: #09aadb;
  vertical-align: baseline;
}

#forgot-card {
  padding-left: 15px;
}

#label {
  margin-top: 20px;
}

.ui-spinner-input {
  width: 1px;
  /* Enter your own width above */
}
</style>