import axios from "axios";

export class Log {
    async sendLog(url,email,message){
    const body = JSON.stringify({email:email,message:message});
      await  axios.post(url,body).then(response => {
            console.log(response)
            return response
        })
        .catch(error => {
            console.log(error)
            return error
        })
    }
}