export class Utils {
  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  getBrowserInfo() {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return "IE " + (tem[1] || "");
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M[0];
  }
  defaultLocale() {
    return navigator.language.split("-")[0];
  }
  getHomeUrl(locale, service) {
    let url = window.location.origin + "/";
    if (service == "" || service == undefined) {
      url = url + "?srv=";
    } else {
      url = url + "?srv=" + service;
    }
    if (locale == "" || locale == undefined) {
      url = url + "&lang=" + this.defaultLocale();
    } else {
      url = url + "&lang=" + locale;
    }
    return url;
  }
  getLogOutUrl(locale, service) {
    let url = window.location.origin + "/logout";
    if (service == "" || service == undefined) {
      url = url + "?srv=";
    } else {
      url = url + "?srv=" + service;
    }
    if (locale == "" || locale == undefined) {
      url = url + "&lang=" + this.defaultLocale();
    } else {
      url = url + "&lang=" + locale;
    }
    return url;
  }
  getInstantLogOutUrl(locale, service) {
    let url = window.location.origin + "/logout";
    if (service == "" || service == undefined) {
      url = url + "?srv=";
    } else {
      url = url + "?srv=" + service;
    }
    if (locale == "" || locale == undefined) {
      url = url + "&lang=" + this.defaultLocale();
    } else {
      url = url + "&lang=" + locale;
    }
    url=url+"&instant=1"
    return url;
  }

  getForgotUrl(locale, service) {
    let url = window.location.origin + "/forgot";
    if (service == "" || service == undefined) {
      url = url + "?srv=";
    } else {
      url = url + "?srv=" + service;
    }
    if (locale == "" || locale == undefined) {
      url = url + "&lang=" + this.defaultLocale();
    } else {
      url = url + "&lang=" + locale;
    }
    return url;
  }
  getChangeUrl(locale, service,email) {
    email=encodeURIComponent(email)
    let url = window.location.origin + "/change";
    if (service == "" || service == undefined) {
      url = url + "?srv=";
    } else {
      url = url + "?srv=" + service;
    }
    if (locale == "" || locale == undefined) {
      url = url + "&lang=" + this.defaultLocale();
    } else {
      url = url + "&lang=" + locale;
    }
    if (email == "" || email == undefined) {
      url = url + "&email=";
    } else {
      url = url + "&email=" + email;
    }
    return url;
  }
  getRedirectUrl(service, local, whiteList) {
    let list = whiteList.split(",");
    let protocol
    if (local == true ||local=="true"){
      protocol = "http"
    }else{
      protocol = "https"
    }
    if (service=="" || service==undefined){
      return this.getLandingUrl(this.defaultLocale(),service)
    }else if(list.includes(service)||local==true||local=="true"){
      let loginParameters = "";
      if (service.includes("secured/login_check")){
        loginParameters = "?_username=user&_password=****";
      }
      const url =
      protocol +"://"+
      service + loginParameters;
      return url;
    }else{
      return this.getLandingUrl();
    }
  }
  getLandingUrl(locale, service) {
    let url = window.location.origin + "/landing";
    if (service == "" || service == undefined) {
      url = url + "?srv=";
    } else {
      url = url + "?srv=" + service;
    }
    if (locale == "" || locale == undefined) {
      url = url + "&lang=" + this.defaultLocale();
    } else {
      url = url + "&lang=" + locale;
    }
    return url;
  }
  getTokenForm(redirectUrl, clientId, email){
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = redirectUrl;

    const accessToken = localStorage.getItem("CognitoIdentityServiceProvider." + clientId+ "." + email + ".accessToken");

    const tokenInput = document.createElement('input');
    tokenInput.type = 'hidden';
    tokenInput.name = 'apiToken';
    tokenInput.value = accessToken;

    form.appendChild(tokenInput);
    return form;
  }
  isLandingURL(url){
    const endpoint = "landing";
    const path = new URL(url).pathname;
    return path.split('/').includes(endpoint);
  }
}
