<template>     
  <table class="login-table" v-if="!firstLogin" @keyup.enter="loadingLogin">
    <tr style="padding-bottom: 0;">
      <td>
        <div class="title-table">
          {{ $t("LoginCardTitle") }}
        </div>
      </td>
    </tr>
    <tr style="padding-top: 0;">
      <td >
        <div id="label" class="form-group">
          <label for="Email">Email</label>
          <input
            id="inputEmail"
            type="email"
            v-on:click="cleanInputEmail"
            class="form-control"
            v-model="email"
            v-on="selected"
          />
          <small class="error-empty" v-if="isBadEmail">
            {{ $t("InvalidEmail") }}
          </small>
          <small class="error-empty" v-if="isEmptyEmail">
            {{ $t("RequiredField") }}
          </small>
        </div>
        <div id="label" class="form-group">
          <label for="Password">{{ $t("PasswordLabel") }}</label>
          <div class="input-group">
            <input
              id="inputPassword"
              type="password"
              class="form-control"
              v-on:click="cleanInputPassword"
              v-model="password"
            />
            <span
            style="cursor: pointer;"
              v-on:click='showPasswordInLogin("inputPassword","show-password-login")'
              class="input-group-text"
              id="show-password-login"
              ><i class="fa fa-eye" aria-hidden="true"></i
            ></span>
          </div>
            <small class="error-empty" v-if="isEmptyPassword" >
            {{ $t("RequiredField") }}
          </small>
        </div>
        <div class="error-table" v-if="passwordIsExpired">
          {{ $t("PasswordExpired") }}
        </div>
        <div class="error-table" v-if="isError">
          {{ $t("FailledLogin") }}
        </div>
        <div class="error-table" v-if="isLimited">
          {{ $t("LimitExceeded") }}
        </div>
        <div class="error-table" v-if="passwordIsNeedReset">
          {{ $t("NeedResetPassword2") }}
        </div>
        <div id="sumbit" class="form-group">
          <button
            id="loginEnter"
            type="submit"
            class="btn btn-lg btn-primary btn-block"
            v-on:click="loadingLogin"
          >
            <div
              class="spinner-border spinner-border-sm text-light"
              role="status"
              v-if="isSpinner"
            >
              <span class="sr-only"></span>
            </div>
            {{ $t("SubmitLogin") }}
          </button>
        </div>
        <div class="forgot-table">
          <a v-on:click="goToForgot">{{ $t("ForgotPassword") }}</a>
        </div>
        <BottomCard />
      </td>
    </tr>
  </table>
  <table
    class="login-table"
    v-if="firstLogin"
    @keyup.enter="loadingNewPassword"
  >
    <div class="title-table">
      {{ $t("FirstLoginCardTitle") }}
    </div>
    <div id="label" class="form-group">
      <label for="Password">{{ $t("NewPasswordLabel") }}</label>
      <div class="input-group ">
        <input
          id="inputNewPassword"
          type="password"
          class="form-control"
          v-model="newPassword"
          v-on:click="cleanInputNewPassword"
        />
        <span
        style="cursor: pointer;"
              v-on:click='showPasswordInLogin("inputNewPassword","show-password-first-login")'
              class="input-group-text"
              id="show-password-first-login"
              ><i class="fa fa-eye" aria-hidden="true"></i
          ></span>
      </div>
      <small class="error-empty" v-if="isEmptyNewPassword">
        {{ $t("RequiredField") }}
      </small>
    </div>
    <div id="label" class="form-group">
      <label for="Password">{{ $t("RepeatNewPasswordLabel") }}</label>
      <div class="input-group ">
        <input
          id="inputRepeatNewPassword"
          type="password"
          class="form-control"
          v-model="repeatNewPassword"
          v-on:click="cleanInputRepeatNewPassword"
        />
         <span
         style="cursor: pointer;"
              v-on:click='showPasswordInLogin("inputRepeatNewPassword","show-repeat-password-first-login")'
              class="input-group-text"
              id="show-repeat-password-first-login"
              ><i class="fa fa-eye" aria-hidden="true"></i
            ></span>
      </div>
      <small class="error-empty" v-if="isEmptyRepeatNewPassword">
        {{ $t("RequiredField") }}
      </small>
    </div>
    <div class="weak-table" v-if="isWeakPassword">
      {{ $t("WeakPassword") }}
      <br />
      <ul>
        <li>{{ $t("LengthPassword") }}</li>
        <li>{{ $t("NeedNumber") }}</li>
        <li>{{ $t("NeedSpecialCharacter") }}</li>
        <li>{{ $t("NeedCapitalized") }}</li>
        <li>{{ $t("NeedLowerCase") }}</li>
      </ul>
    </div>
    <div class="error-table" v-if="isPasswordsDoNotMatch">
      {{ $t("PasswordsDoNotMatch") }}
    </div>
    <div class="error-table" v-if="isNewPasswordDefaultError">
      {{ $t("NewPasswordDefaultError") }}
    </div>
    <div class="sucess-table" v-if="isSuccessNewPassword">
      {{ $t("SucessChangePassword") }}
    </div>
    <div id="sumbit" class="form-group">
      <button
        id="loginEnter"
        type="submit"
        class="btn btn-lg btn-primary btn-block"
        v-on:click="loadingNewPassword"
      >
        <div
          class="spinner-border spinner-border-sm text-light"
          role="status"
          v-if="isSpinnerNewPassword"
        >
          <span class="sr-only"></span>
        </div>
        {{ $t("ChangePassword") }}
      </button>
    </div>
    <br />
    <BottomCard />
  </table>
</template>

<script>
import BottomCard from "@/components/BottomCard.vue";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
export default {
  components: {
    BottomCard,
  },
  data() {
    return {
      email: "",
      password: "",
      newPassword: "",
      repeatNewPassword: "",
      isBadEmail: false,
      isSpinner: false,
      isError: false,
      isEmptyPassword: false,
      isEmptyEmail: false,
      needResetPassword: false,
      firstLogin: false,
      sessionUserAttributes: null,
      isEmptyNewPassword: false,
      isEmptyRepeatNewPassword: false,
      isSpinnerNewPassword: false,
      isWeakPassword: false,
      isPasswordsDoNotMatch: false,
      isSuccessNewPassword: false,
      user: false,
      isLimited: false,
      passwordIsNeedReset: false,
      passwordIsExpired: false,
    };
  },
  mounted() {},
  methods: {
    login: async function () {
      this.email = this.email.trim();
      this.password = this.password.trim();
      if (this.password == undefined || this.password == "") {
        this.isEmptyPassword = true;
        document
          .getElementById("inputPassword")
          .style.setProperty("border-color", "#C44249");
      } else {
        this.isEmptyPassword = false;
        document
          .getElementById("inputPassword")
          .style.setProperty("border-color", "#7F7F7F");
      }
      if (this.email == undefined || this.email == "") {
        this.isEmptyEmail = true;
        this.isSpinner = false;
        this.isError = false;
        this.isLimited = false;
        this.isBadEmail = false;
        document
          .getElementById("inputEmail")
          .style.setProperty("border-color", "#C44249");
      } else if (!this.$utils.validateEmail(this.email)) {
        this.isBadEmail = true;
        this.isSpinner = false;
        this.isEmptyEmail = false;
        document
          .getElementById("inputEmail")
          .style.setProperty("border-color", "#C44249");
      } else if (this.password != undefined && this.password != "") {
        document
          .getElementById("inputPassword")
          .style.setProperty("border-color", "#e5e6e8");
        document
          .getElementById("inputEmail")
          .style.setProperty("border-color", "#e5e6e8");
        this.isEmptyEmail = false;
        this.isEmptyPassword = false;
        this.isBadEmail = false;
        const poolData = await this.$cog.poolData(
          this.$userPoolId,
          this.$clientId
        );
        const user = new CognitoUser({
          Username: this.email,
          Pool: poolData,
        });
        user.setAuthenticationFlowType("USER_PASSWORD_AUTH");
        const authDetails = new AuthenticationDetails({
          Username: this.email,
          Password: this.password,
        });
        this.user = user;
        user.authenticateUser(authDetails, {
          onSuccess: () => {
            localStorage.setItem("email", this.email);
            document.cookie = "wherexCookie" + "=" + this.$userInfo.getAcces(this.$clientId,this.email) + ";" + "5m" + ";path=/;domain=.wherex.com;";
            const service = this.$route.query.srv;
            const lang = this.$i18n.locale;
            if (service == "" || service == undefined) {
              window.location.href = this.$utils.getLandingUrl(lang, service);
            } else {
              window.location.href = this.$utils.getRedirectUrl(
                service,
                this.$local,
                this.$clientId,
                this.email,
                this.$whiteList
              );
            }
          },
          onFailure: async (err) => {
            if (err.message == "Password attempts exceeded") {
              await this.$log.sendLog(this.$logUrl,this.email,err.message);
              this.isLimited = true;
              this.isError = false;
              this.passwordIsNeedReset = false;
              this.passwordIsExpired = false;
            } else if (err.message == "Password reset required for the user") {
              await this.$log.sendLog(this.$logUrl,this.email,err.message);
              this.passwordIsNeedReset = true;
              this.isError = false;
              this.isLimited = false;
              this.passwordIsExpired = false;
            } else if (
              err.message ==
              "Temporary password has expired and must be reset by an administrator."
            ) {
              await this.$log.sendLog(this.$logUrl,this.email,err.message);
              this.needResetPassword = false;
              this.isSpinner = false;
              this.isEmptyPassword = false;
              this.isEmptyEmail = false;
              this.passwordIsExpired = true;
              this.isError = false;
            } else {
              if (err.message!="Incorrect username or password."){
                await this.$log.sendLog(this.$logUrl,this.email,err.message);
              }
              this.isError = true;
              this.isLimited = false;
              this.passwordIsNeedReset = false;
              this.passwordIsExpired = false;
            }
            this.needResetPassword = false;
            this.isSpinner = false;
            this.isEmptyPassword = false;
            this.isEmptyEmail = false;
          },
          newPasswordRequired: (userAttributes) => {
            this.firstLogin = true;
            delete userAttributes.email_verified;
            delete userAttributes.email;
            this.sessionUserAttributes = userAttributes;
          },
        });
      } else {
        this.isSpinner = false;
      }
    },
    goToForgot: function () {
      const service = this.$route.query.srv;
      const lang = this.$i18n.locale;
      window.location.href = this.$utils.getForgotUrl(lang, service);
    },
    loadingLogin: async function () {
      this.$data.isSpinner = true;
      await this.login();
    },
    cleanInputEmail: async function () {
      this.isBadEmail = false;
      this.isEmptyEmail = false;
      document
        .getElementById("inputEmail")
        .style.setProperty("border-color", "#e5e6e8");
    },
    cleanInputPassword: async function () {
      this.isEmptyPassword = false;
      document
        .getElementById("inputPassword")
        .style.setProperty("border-color", "#e5e6e8");
    },
    cleanInputNewPassword: async function () {
      this.isEmptyNewPassword = false;
      document
        .getElementById("inputNewPassword")
        .style.setProperty("border-color", "#e5e6e8");
    },
    cleanInputRepeatNewPassword: async function () {
      this.isEmptyRepeatNewPassword = false;
      document
        .getElementById("inputRepeatNewPassword")
        .style.setProperty("border-color", "#e5e6e8");
    },
    loadingNewPassword: async function () {
      this.isSpinnerNewPassword = true;
      await this.changeNewpassword();
    },
    sleep: async function (ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    changeNewpassword: async function () {
      this.newPassword = this.newPassword.trim();
      this.repeatNewPassword = this.repeatNewPassword.trim();
      this.isEmptyNewPassword = false;
      this.isEmptyRepeatNewPassword = false;
      this.isPasswordsDoNotMatch = false;
      this.isNewPasswordDefaultError = false;
      this.isWeakPassword = false;
      if (this.newPassword == "") {
        this.isEmptyNewPassword = true;
        document
          .getElementById("inputNewPassword")
          .style.setProperty("border-color", "#C44249");
      }
      if (this.repeatNewPassword == "") {
        this.isEmptyRepeatNewPassword = true;
        document
          .getElementById("inputRepeatNewPassword")
          .style.setProperty("border-color", "#C44249");
      }
      if (
        this.repeatNewPassword != this.newPassword &&
        this.newPassword != "" &&
        this.repeatNewPassword != ""
      ) {
        this.isPasswordsDoNotMatch = true;
      }
      if (
        this.newPassword != "" &&
        this.repeatNewPassword != "" &&
        this.repeatNewPassword == this.newPassword
      ) {
        this.user.completeNewPasswordChallenge(
          this.newPassword,
          this.sessionUserAttributes,
          {
            onSuccess: async () => {
              localStorage.setItem("email", this.email);
              this.isSuccessNewPassword = true;
              this.isSpinnerNewPassword = false;
              localStorage.setItem("email", this.email);
              const service = this.$route.query.srv;
              const lang = this.$i18n.locale;
              if (service == "" || service == undefined) {
                window.location.href = this.$utils.getLandingUrl(lang, service);
              } else {
                window.location.href = this.$utils.getRedirectUrl(
                  service,
                  this.$local,
                  this.$clientId,
                  this.email,
                  this.$whiteList
                );
              }
            },
            onFailure:async (err) => {
              if (
                err.message.includes("Password does not conform to policy:")
              ) {
                this.isWeakPassword = true;
              } else {
                await this.$log.sendLog(this.$logUrl,this.email,err.message);
                this.isNewPasswordDefaultError = true;
              }
              this.isSpinnerNewPassword = false;
            },
          }
        );
      } else {
        this.isSpinnerNewPassword = false;
      }
    },
    showPasswordInLogin: async function (idInput,idAddons) {
      if (document.getElementById(idInput).type == "text") {
        document.getElementById(idInput).type = "password";
        document.getElementById(idAddons).innerHTML =
          '<i class="fa fa-eye" aria-hidden="true"></i>';
      } else {
        document.getElementById(idInput).type = "text";
        document.getElementById(idAddons).innerHTML =
          '<i class="fa fa-eye-slash" aria-hidden="true"></i>';
      }
    },
  },
};
</script>

<style>
@import "../assets/css/table.css";
</style>