import { CognitoUserPool } from "amazon-cognito-identity-js";
import dotenv from 'dotenv'

export class CognitoService {
    poolData(userPoolId, clientId) {
        dotenv.config()
        const poolData = {
            UserPoolId: userPoolId,
            ClientId: clientId
        }
        return new CognitoUserPool(poolData)
    }
}
