import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Status from '../views/LogOut.vue'
import NotFoundComponent from '../views/NotFoundComponent.vue'
import Forgot from '../views/Forgot.vue'
import Change from '../views/ChangePasswordByCode.vue'
import ChangePassword from '../views/ChangePassword.vue'
import ErrorView from '../views/Error.vue'
import Landing from '../views/Landing.vue'




const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Status
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: Forgot
  },
  {
    path: '/change',
    name: 'Change',
    component: Change
  },
  {
    path: '/changepassword',
    name: 'ChangePassword',
    component: ChangePassword
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorView
  },
  {
    path: '/Landing',
    name: 'Landing',
    component: Landing
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFoundComponent
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach(()=>{
  document.title="Wherex"
})

export default router
