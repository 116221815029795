<template>
 <div id="copyrightZone" class="copyright">2015-{{this.year}} &copy; Wherex.</div>
</template>

<script>
export default{
    name:"Copyright",
    components:{},
    data(){
        return {
            year:new Date().getFullYear()
        }
    },
    mounted(){

    },
    methods(){

    }
}
</script>
<style>
.copyright {

}

#copyrightZone {
  text-align: end;
  color: #1F3663;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>