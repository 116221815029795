<template>
  <div class="language col-xs-6">
    <a id="en" class="selectorLocale" v-on:click="reload('en')">English</a> /
    <a id="es" class="selectorLocale" v-on:click="reload('es')">Español</a>
  </div>
</template>

<script>
export default {
  name: "ChangeLocale",
  components: {},
  data() {
    return {
      url: window.location,
    };
  },
  mounted() {
    var locale = this.$route.query.lang;
    if(locale != undefined && locale!="undefined" ){
      this.$i18n.locale = locale;
      if (locale != null){
         document.getElementById(locale).style.setProperty("color", "#191D38");
         document.getElementById(locale).style.setProperty("font-weight", "bold");
      }
    }else{
      this.$i18n.locale =navigator.language.split("-")[0]
      document.getElementById(this.$i18n.locale).style.setProperty("color", "#191D38");
      document.getElementById(this.$i18n.locale).style.setProperty("font-weight", "bold"); 
    }
  },
  methods: {
    reload: async function (lang) {
      if (lang == "es"){
        document.getElementById("en").style.setProperty("color", "#1F3663");
        document.getElementById("en").style.setProperty("font-weight", "normal");
      }else{
        document.getElementById("es").style.setProperty("color", "#1F3663");
        document.getElementById("es").style.setProperty("font-weight", "normal");
      }
      console.log(document.getElementById(lang))
      document.getElementById(lang).style.setProperty("color", "#191D38");
      document.getElementById(lang).style.setProperty("font-weight", "bold");
      this.$i18n.locale = lang;
    },
  },
};
</script>

<style>
.selectorLocale {
  cursor: pointer;
  text-decoration: none;
  color:#1F3663
}

</style>

