import { createI18n } from 'vue-i18n/index'


const messages = {
  es: {
    Password:"Contraseña",
    LoginCardTitle: 'Ingresa tu Cuenta',
    ForgotCardTitle:'Ingresa tu Cuenta',
    FirstLoginCardTitle: 'Ingresa tu nueva contraseña',
    PasswordLabel: 'Contraseña',
    ForgotPassword: '¿Olvidaste tu contraseña?',
    VisitWherex: 'visita Wherex.com',
    Register: '¿No eres proveedor?',
    LinkRegister: 'Regístrate aquí',
    SubmitLogin: 'Ingresar',
    ForgotText: 'Ingresa el email que utilizas en la plataforma y te enviaremos un correo con un codigo para restablecer tu contraseña.',
    Submit: 'Enviar',
    Back: 'Volver',
    RequiredField: 'Campo requerido',
    InvalidEmail: "Email no válido (Ej:{'nombre'}{'@'}{'empresa'}.com).",
    FailledLogin: 'Clave o nombre de usuario incorrecto.',
    NeedRessetPassword: 'Se necesita reestablecer tu contraseña, ingresa',
    Here: 'Aquí',
    ServiceNotAvaible: 'Servicio no disponible',
    SuccesSendEmail: 'Hemos enviado el <strong>código de seguridad</strong>, por favor revisa tu bandeja de entrada',
    AlertNavegator: 'Estás utilizando un <strong>navegador</strong> que no es 100% compatible con esta plataforma. Te recomendamos usar <strong><a class="link-alert-comp-browser-white" href="https://www.google.com/intl/es/chrome/" target="_blank">Google Chrome</a></strong> o <strong><a class="link-alert-comp-browser-white" href="https://www.microsoft.com/es-es/edge" target="_blank">Microsoft Edge</a>.</strong>',
    Or: 'o',
    PasswordsDoNotMatch: 'Las contraseñas no coinciden.',
    CodeIsNotCorrect: 'El código no es el correcto',
    WeakPassword: 'La contraseña no cumple los requisitos.',
    ChangePassword: 'Cambiar contraseña',
    Code: 'Código de seguridad',
    RepeatPassword: 'Repetir contraseña',
    ChangeTitle: 'Cambiar contraseña',
    NewPasswordLabel:'Nueva contraseña',
    RepeatNewPasswordLabel:'Repita la nueva contraseña',
    NewPasswordDefaultError:'Algo ha salido mal, reintentelo',
    LimitExceeded:'Limite de intentos superado, por favor intente mas tarde',
    OldPassword:'Contraseña Actual',
    BadPassword:'Contraseña incorrecta',
    DefaultError:'Servicio no disponible',
    LimitedTime:'Tiempo de carga excedido',
    LogOutTittle:'¿Cerrar sesion?',
    LogOut:'Cerrar',
    SucessChangePasswordText: 'La contraseña ha sido cambiada, redireccionando.',
    SuccesChangePasswordTittle:'Contraseña cambiada',
    SucessChangePassword: 'Contraseña cambiada, redireccionando.',
    NeedNumber:'Debe tener números.',
    NeedSpecialCharacter:'Debe tener un carácter especial (!?%&).',
    NeedCapitalized:'Debe tener mayúsculas.',
    NeedLowerCase:'Debe tener minúsculas.',
    LengthPassword:'Debe tener al menos 8 caracteres.',
    UserNotFound:'Usuario no registrado',
    UserNotEnabled: 'Usuario no activado para esta aplicación.',
    NeedResetPassword2:'Se necesita reestablecer la contraseña, revisa tu correo.',
    IsCannotBeReset:'No puedes restablecer tu contraseña, dado que tienes una contraseña temporal. Revisa tu bandeja de entrada e ingresa.',
    PasswordExpired:'La contraseña temporal expiró. Se ha generado una nueva contraseña temporal, revisa tu inbox e ingresa con esta.',
    AuthError: 'Autenticación fallida.',
    WherexPayLabel:'Plataforma de <strong>gestión de cobranza, información financiera y financiamiento.</strong>',
    WherexSourcingLabel:' Plataforma de <strong>cotizaciones y licitaciones</strong> que conecta a compradores y proveedores mediante Inteligencia Artificial.',
    ActivateCompany:'Activa tu empresa',
    MoreInformation:'Más información',
    OkCompany:'Tu empresa está activada en la plataforma.',
    NotOkCompany:'Tu empresa no está activa en esta plataforma. <strong>Actívala en unos pocos pasos.</strong>',
    LandingLabel:'Visualiza rápidamente las plataformas en las que participa tu empresa.',
    Hello:"¡Hola!",
    Exit:"Salir",
    LabelSourcing1:"¿Eres proveedor?",
    LabelSourcing2:"¿Eres comprador?",
    LabelRegisterSourcing1:"Inscríbete",
    LabelRegisterSourcing2:"Solicita demo",
    ChangePasswordCodeLabel:"Revisa <strong>tu bandeja de entrada</strong> del correo que ingresaste. Si el mail no aparece o tarda en llegar, revisa tu carpeta de no deseados o puedes", 
    ReSendEmail:"reenviar código de seguridad.",
    CodeSubLabel:"Ingresa el código de 6 dígitos que recibiste en tu correo.",
    PasswordSubLabel:"Al menos 8 caracteres, debe incluir números, mayúsculas y un caracter especial.",
    ResetPasswordTitle: "Reestablecer contraseña",
    SucessResetPasswordAlert:"La contraseña ha sido restablecida con éxito.",
    SucessResetPasswordLabel:"Debes volver al inicio e ingresar nuevamente a la plataforma.",
    SucessResetPasswordButton:"Volver al inicio",
    ExpiredCode:"Código expirado.",
    ActivatePay:"Activa gratis",
    PayTerm:"Al ingresar en <strong>Wherex Pay</strong> estas aceptando los",
    PayTermLink:"Términos y condiciones."
    

  },
  en: {
    Password:"Password",
    LoginCardTitle: 'Login to your account',
    ForgotCardTitle:'Enter your account',
    FirstLoginCardTitle: 'Enter your new password',
    PasswordLabel: 'Password',
    ForgotPassword: 'Forgot your password?',
    VisitWherex: 'visit Wherex.com',
    Register: 'You are not a supplier?',
    LinkRegister: 'Sign up here',
    SubmitLogin: 'Login',
    ForgotText: 'Enter the email you use on the platform and we will send you an email with a code to reset your password.',
    Submit: 'Submit',
    Back: 'Back',
    RequiredField: 'Required field',
    InvalidEmail: "Invalid email (Ej:{'name'}{'@'}{'company'}.com)",
    FailledLogin: 'Invalid password or username.',
    NeedRessetPassword: 'You need to reset your password, enter ',
    Here: 'Here',
    ServiceNotAvaible: 'Service not avaible',
    SuccesSendEmail: 'We have sent a <strong>security code</strong>. If the email is valid, please check your inbox.',
    AlertNavegator: 'You are using a <strong>browser</strong> that is not 100% compatible with our platform. We recommend using <strong><a class="link-alert-comp-browser-white" href="https://www.google.com/intl/es/chrome/" target="_blank">Google Chrome</a></strong> or <strong><a class="link-alert-comp-browser-white" href="https://www.microsoft.com/es-es/edge" target="_blank">Microsoft Edge</a>.</strong>',
    Or: 'or',
    PasswordsDoNotMatch: 'Passwords do not match.',
    CodeIsNotCorrect: 'The code is not correct.',
    WeakPassword: 'Password does not meet requirements.',
    ChangePassword: 'Change password',
    Code: 'Security Code',
    RepeatPassword: 'Repeat password',
    ChangeTitle: 'Change password',
    NewPasswordLabel:'New password',
    RepeatNewPasswordLabel:'Repeat new password',
    NewPasswordDefaultError:'Something has gone wrong, please try again',
    LimitExceeded:'Attempt limit exceeded, please try after some time.',
    OldPassword:'Current password',
    BadPassword:'Bad Password',
    DefaultError:'Service not available',
    LimitedTime:'time limit',
    LogOutTittle:'Log out',
    LogOut:'Log out',
    SucessChangePasswordText: 'The password has been changed, redirecting.',
    SuccesChangePasswordTittle:'Password changed',
    SucessChangePassword: 'Password changed, redirecting.',
    NeedNumber:'Must have numbers.',
    NeedSpecialCharacter:'Must have a special character (!?%&).',
    NeedCapitalized:'Must be capitalized.',
    NeedLowerCase:'Must be lowercase.',
    LengthPassword:'Minimum length 8 characters.',
    UserNotFound:'Unregistered user',
    UserNotEnabled: 'User is not enabled for app',
    NeedResetPassword2:'Password reset required, check your email.',
    IsCannotBeReset:'You cannot reset your password, because you have a temporary password. Check your inbox and sign in.',
    PasswordExpired:'Temporary password has expired, a new temporary password has been generated, please check your inbox and log in again.',
    AuthError: 'Authentication Error.',
    WherexPayLabel:'Web platform for <strong>quotations and tenders</strong> that connects buyers and suppliers through artificial intelligence.',
    WherexSourcingLabel:'Web platform for<strong> collection management, cash flow forecasting and supplier chain finance.</strong>',
    ActivateCompany:'Activate your company',
    MoreInformation:'more information',
    OkCompany:'Your company is activated on the platform.',
    NotOkCompany:'Your company is not active on this platform. <strong>Activate it in a few steps.</strong>',
    LandingLabel:'Quickly view the platforms in which your company participates.',
    Hello:"¡Welcome!",
    Exit:"Logout",
    LabelSourcing1:"For suppliers",
    LabelSourcing2:"For buyers",
    LabelRegisterSourcing1:"Sign Up",
    LabelRegisterSourcing2:"Request demo",
    ChangePasswordCodeLabel:"Check the <strong>inbox of the email you registered</strong>. If the email does not appear or takes a long time to arrive, check your spam folder or you can",
    ReSendEmail:"resend security code.",
    CodeSubLabel:"Enter the 6-digit code you received in your email.",
    PasswordSubLabel:"At least 8 characters, must include numbers, capital letters and a special character.",
    ResetPasswordTitle: "Reset Password",
    SucessResetPasswordAlert:"The password has been successfully reset.",
    SucessResetPasswordLabel:"You must return to the beginning and enter the platform again.",
    SucessResetPasswordButton:"Back",
    ExpiredCode:"Expired code.",
    ActivatePay:"Activate for free",
    PayTerm:"By using Wherex Pay you accept our",
    PayTermLink:"Terms and conditions"
  }
}

const i18n = createI18n({
  legacy: false, 
  locale: navigator.language.split('-')[0], 
  fallbackLocale: 'es', 
  globalInjection: true,
  messages, 
})

export default i18n
