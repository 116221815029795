<template>
  <br>
</template>

<script>
import { CognitoUser } from "amazon-cognito-identity-js";
export default {
  name: "StatusCard",
  components: {},
  data() {
    return {
      email: this.$userInfo.getEmail(this.$clientId),
    };
  },
  async mounted() {
    const isLogin = this.$userInfo.isLogin(this.$clientId);
    const lang = this.$route.query.lang;
    const callback = this.$route.query.srv;
    const utils = this.$utils
    if (isLogin) {
      var poolData = await this.$cog.poolData(this.$userPoolId, this.$clientId);
      var user = new CognitoUser({
        Username: this.email,
        Pool: poolData,
      });
    }
    var errorMsg = this.$t("DefaultError");
    switch (this.$route.query.type) {
      case "UserNotEnabled":
        errorMsg = this.$t("UserNotEnabled");
        break;
      case "AuthError":
        errorMsg = this.$t("AuthError");
        break;
    }
    
    this.$swal({
      title: "Error",
      text: errorMsg,
      icon: "error",
      showCloseButton: false,
      type: "error",
    }).then(function() {
      if (isLogin) {
        user.signOut(() => {
          localStorage.removeItem("email");
          document.cookie = "wherexCookie" + "=" + "" + ";" + "5m" + ";path=/";
           window.location.href = utils.getHomeUrl(lang, callback);
        });
      } else {
        window.location.href = utils.getHomeUrl(lang, callback);
      }
    });
  },
  methods: {},
};
</script>

<style></style>
