export class UserInfo {
    isLogin(clientId) {
        const email = localStorage.getItem("CognitoIdentityServiceProvider." + clientId + ".LastAuthUser");
        const accessToken = localStorage.getItem("CognitoIdentityServiceProvider." + clientId + "." + email + ".accessToken");
        const refereshToken = localStorage.getItem("CognitoIdentityServiceProvider." + clientId + "." + email + ".refreshToken");
        const idToken = localStorage.getItem("CognitoIdentityServiceProvider." + clientId + "." + email + ".idToken");
        if (accessToken != null && refereshToken != null && idToken != null) {
            return true
        }
        return false
    }

    getEmail(clientId) {
        var email 
        email = localStorage.getItem("email");
        if (email == null){
            email = localStorage.getItem("CognitoIdentityServiceProvider." + clientId + ".LastAuthUser");
            localStorage.setItem("email",email)
        }
        if (email == ""){
            email = localStorage.getItem("CognitoIdentityServiceProvider." + clientId + ".LastAuthUser");
            localStorage.setItem("email",email)
        }  
        return email
    }
    getAcces(clientId, email) {
        const accessToken = localStorage.getItem("CognitoIdentityServiceProvider." + clientId + "." + email + ".accessToken");
        return accessToken
    }
    getRefresh(clientId, email) {
        const accessToken = localStorage.getItem("CognitoIdentityServiceProvider." + clientId + "." + email + ".refreshToken");
        return accessToken
    }
}